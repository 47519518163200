<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 答题管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-select v-model="departmentSelected" class="mr10" @change="change">
                    <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">{{item.title}}
                    </el-option>
                    <!--<el-option key="bbk" label="步步高" value="bbk"></el-option>-->
                    <!--<el-option key="xtc" label="小天才" value="xtc"></el-option>-->
                    <!--<el-option key="imoo" label="imoo" value="imoo"></el-option>-->
                </el-select>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>

                <!--<el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>-->
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="username"  align="center" label="答题者姓名" width="155">
                </el-table-column>
                <el-table-column prop="department"  align="center" label="所属部门" width="155">
                </el-table-column>
                <el-table-column prop="qname"  align="center" label="问卷标题">
                </el-table-column>
                <el-table-column prop="sumfraction"  align="center" label="总得分" width="200">
                </el-table-column>
                <el-table-column prop="usetime"  align="center" label="答题总用时" width="200">
                </el-table-column>
                <el-table-column prop="datetime"  align="center" label="提交时间" sortable width="155">
                </el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="70%">
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="答题者姓名" prop="username">
                    <el-input v-model="form.username" style="width:600px;"></el-input>
                </el-form-item>
                <el-form-item label="所属部门" prop="username">
                    <el-input v-model="form.department" style="width:600px;"></el-input>
                </el-form-item>
                <el-form-item label="问卷标题" prop="qname">
                    <el-input v-model="form.qname" type="textarea" style="width:600px;" :rows="3"></el-input>
                </el-form-item>
                <el-form-item label="总得分" prop="sumfraction">
                    <!--<el-input v-model="form.countdown" placeholder="请输入答题倒计时"></el-input>-->
                    <el-input v-model="form.sumfraction" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="答题总用时" prop="usetime">
                    <!--<el-input v-model="form.countdown" placeholder="请输入答题倒计时"></el-input>-->
                    <el-input v-model="form.usetime" style="width:600px;"></el-input>
                </el-form-item>
                <el-form-item label="题目">
                    <div class="qusWrap">
                        <div class="qusItem" v-for="(item,index) in form.answerList" :key="item.id">
                            <div class="qusItem_left">
                                <p class="qusTitle">{{index+1}}.  {{item.title}}  ({{item.fraction}}分)</p>
                                <!--单选题选项-->
                                <div v-if="item.type==0">
                                    <el-radio-group v-model="item.content">
                                        <el-radio v-for="(value,i) in item.optionsList" :key="i" :label="value.content">{{value.content}}</el-radio>
                                    </el-radio-group>
                                    <div style="color:red">正确答案：{{item.answer}}</div>
                                </div>
                                <!--多选题选项-->
                                <div v-if="item.type==3">
                                    <el-checkbox-group v-model="item.content">
                                        <el-checkbox v-for="(value,i) in item.optionsList" :key="i" :label="value.content">{{value.content}}</el-checkbox>
                                    </el-checkbox-group>
                                    <div style="color:red">正确答案：{{item.answer}}</div>
                                </div>
                                <!--填空题答案-->
                                <div v-if="item.type==1">
                                    <!--<el-input v-model="form.sort" style="width:100px"></el-input>天-->
                                    <el-input  style="width:300px; margin-right: 20px" v-model="item.content"></el-input>
                                    <div style="color:red">正确答案：{{item.answer}}</div>
                                </div>
                                <!--问答题答案-->
                                <div v-if="item.type==2">
                                    <!--<el-input v-model="form.sort" style="width:100px"></el-input>天-->
                                    <el-input type="textarea" :rows="5" style="width:600px; margin-right: 20px" v-model="item.content"></el-input>
                                    <div style="color:red">正确答案：{{item.answer}}</div>
                                </div>
                            </div>
                            <div class="qusItem_right">
                                <i class="el-icon-check dui" v-if="item.content_t == item.answer_t"></i>
                                <i class="el-icon-close cuo" v-else></i>
                            </div>
                        </div>

                    </div>

                    <!--<quill-editor ref="myTextEditor" v-model="form.details" :options="editorOption"></quill-editor>-->
                    <!--<el-button class="editor-btn" type="primary" @click="submit">提交</el-button>-->
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideEditVisible">关 闭</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'basetable',
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                letter :['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id:'',
                    qid:'',
                    qname: '',
                    username: '',
                    usetime: '',
                    sumfraction: '',
                    department: '',
                    answerList: [],
                    datetime: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                loading:false,
                rules: {
                    createname: [
                        { required: true, message: '请输入创建者姓名', trigger: 'blur' }
                    ],
                    wjname: [
                        { required: true, message: '请输入问卷标题', trigger: 'blur' }
                    ],
                },
                mediaList: [  //上传类型列表
                    { id: '0', title: '单选题' },
                    { id: '1', title: '填空题' },
                    { id: '2', title: '问答题' },
                ],
                departmentList:[
                    { id: 0, title: "请选择部门"},
                    { id: 1, title: "总经办"},
                    { id: 2, title: "品牌部-星代言"},
                    { id: 3, title: "品牌部-星企划"},
                    { id: 4, title: "品牌部-新媒体"},
                    { id: 5, title: "品牌部-星文化"},
                    { id: 6, title: "运营部-客服1部"},
                    { id: 7, title: "运营部-客服2部"},
                    { id: 8, title: "运营部-客服3部"},
                    { id: 9, title: "整外科"},
                    { id: 10, title: "皮肤科"},
                    { id: 11, title: "微整科"},
                    { id: 12, title: "口腔科"},
                    { id: 13, title: "医护部"},
                    { id: 14, title: "财务部"},
                    { id: 15, title: "人力行政部"},
                ],
                departmentSelected: 0,
            }
        },
        created() {
            //获取初始数据
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.prize === this.del_list[i].prize) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                this.loading=false;
                // console.log(res);
                this.form.imgid=res.data;
                this.form.imgUrl = URL.createObjectURL(file.raw);
                this.getData();
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var departmentSelected="";
                if(this.departmentSelected!=0){
                    departmentSelected=this.departmentList[this.departmentSelected]['title']
                }
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number,
                    departmentSelected: departmentSelected
                });
                console.log({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number,
                    departmentSelected: departmentSelected
                });
                this.$api.post('InsideAnswer/getUserList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(this.tableData);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.prize;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id:null,
                        qid: null,
                        qname: null,
                        username: 10,
                        usetime: null,
                        sumfraction: 0,
                        department: null,
                        datetime: null,
                        answerList: [],
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    for(let i=0;i<item.answerList.length;i++){
                        item.answerList[i]['content_t']=item.answerList[i]['content'].toString().trim().toLocaleLowerCase().replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\，|\。|\、|\；|\<|\.|\>|\/|\?]/g,"");
                        item.answerList[i]['answer_t']=item.answerList[i]['answer'].toString().trim().toLocaleLowerCase().replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\，|\。|\、|\；|\<|\.|\>|\/|\?]/g,"");
                    }
                    this.form = {
                        id:item.id,
                        qid: item.qid,
                        qname: item.qname,
                        username: item.username,
                        usetime: item.usetime,
                        sumfraction: item.sumfraction,
                        department: item.department,
                        datetime: item.datetime,
                        answerList: item.answerList,
                    };
                }
                this.editVisible = true;
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            change(){
                this.getData();
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            let titleids=[];
                            this.form.titleList.forEach(function(item, index, arr) {
                                titleids.push(item.id);
                            });
                            params={
                                createname: this.form.createname,
                                wjname: this.form.wjname,
                                countdown: this.form.countdown,
                                titleids: titleids.join(',')
                            };
                        }else{
                            params={
                                id: this.form.id,
                                createname: this.form.createname,
                                wjname: this.form.wjname,
                                countdown: this.form.countdown,
                                titleids: null
                            };
                        }
                        this.$api.post('InsideQus/saveQus', this.$qs.stringify(params), res => {
                            console.log(res);
                            this.getData();
                            this.$message.success(res.msg);
                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
            },
            // 确定删除
            deleteRow(){
                // console.log(this.form);
                // return;
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                this.$api.post('InsideQus/deleteQus', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            hideEditVisible(){
                this.editVisible=false;
            },
            //添加更多题目
            addMoreProcess(){
                let temp={
                    qid: this.form.id,
                    qname: this.form.wjname,
                    title: null,
                    type: '0',
                    optionsList:[],
                    nullAnswer: null,
                    radio: 0,
                    QusAnswer: null,
                };
                this.form.titleList.push(temp);
                console.log(this.form.titleList);
            },
            //添加选项
            addOptions(index){
                let temp={
                    tid: this.form.titleList[index].id,
                    content: null,
                    isright: '0',
                };
                this.form.titleList[index].optionsList.push(temp);
                console.log(this.form.titleList);
            },
            //减少选项
            reduceOptions(index){
                this.form.titleList[index].optionsList.pop();
            },
            //保存题目
            addProcess(index){
                let item=this.form.titleList[index];
                let params=null;
                if(parseInt(item.type)==0){
                    //选择题数据处理方式
                    for (let i=0; i<item.optionsList.length; i++){
                        item.optionsList[i].isright=0;
                    }
                    item.optionsList[item.radio].isright=1;  //正确答案
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 0,
                        optionsList: item.optionsList,
                    };
                }else if(parseInt(item.type)==1){
                    //填空题数据处理方式
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 1,
                        answer: item.nullAnswer,
                    };
                }else if(parseInt(item.type)==2){
                    //问答题数据处理方式
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 2,
                        answer: item.QusAnswer,
                    };
                }
                console.log("我是参数：",params);
                // return;
                this.$api.post('InsideQus/addOrSaveTitle', this.$qs.stringify(params), res => {
                    this.$message.success(res.msg);
                    console.log(res);
                    item.id=res.data;

                }, err => {
                    this.$message.error(err.msg);
                });
            },
            //删除题目
            delProcess(index){
                let temp=this.form.titleList[index];
                if(!temp.id){
                    var tempArr=this.form.titleList;
                    tempArr.forEach(function(item, index, arr) {
                        if(item.id == temp.id) {
                            arr.splice(index, 1);
                        }
                    });
                }else{
                    let params=this.$qs.stringify({
                        id: temp.id,
                    });
                    this.$api.post('InsideQus/delProcess', params, res => {
                        this.$message.success(res.msg);
                        console.log(res);
                        var tempArr=this.form.titleList;
                        tempArr.forEach(function(item, index, arr) {
                            if(item.id == temp.id) {
                                arr.splice(index, 1);
                            }
                        });
                    }, err => {
                        this.$message.error(err.msg);
                    });
                }

            },
        },

    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .itemDetails{
        padding: 18px;
        border: 1px dashed #409EFF
    }
    .qusWrap{
        border: 1px solid #EFEFEF;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        padding: 20px;
        height: auto;
        overflow: hidden;
    }
    .qusTitle{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .qusItem{
        padding-bottom: 25px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px dashed #EFEFEF;
    }
    .qusItem_left{
        width: 60%;
        float: left;
    }
    .qusItem_right{
        width: 40%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .qusItem_right>i{
        font-size: 35px;
        font-weight: bold;
    }
    .dui{
        color: green;
    }
    .cuo{
        color: red;
    }
</style>
